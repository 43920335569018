import React from "react";
import {v4 as uuid} from "uuid";

import Loader from "../components/Loader";

/**
    * Extend the given component with expected behaviour such as a loader and error message handling
    *  
    * @param {React.Component} WrappedComponent - The component that is to be wrapped
    *   @property {Function<Function>} hasLoaded - A function that must be called when the component has successfully loaded. It has a function parameter that will be executed after enough time has passed for the component to load. As of now, it is 2s
    *   @property {Function<string>} hasErrored - A function with an optional error message that can be called when the component has errored.
    * @returns A Higher Order React.Component
 */
function ApplicationBehaviour(WrappedComponent) {
    class BehaviourWrappedComponent extends React.Component {
        constructor(props) {
            super(props);

            this.mandatoryLoadingTime = 300;

            this.state = {
                constructedAt: performance.now(),
                uuid: uuid(),

                loading: true,
                error: false,
                errorMessage: "",
            };
        }

        componentDidCatch(error, info) {
            // Display fallback UI
            this.setState({ error: true });

            // You can also log the error to an error reporting service
            console.error(error, info);
        }

        /**
            * The function that is provided to the WrappedComponent to call when it has successfully loaded itself. 
            * This function will execute its callback and state logic after a specific mandatory timeout which is both for better UX and for better React lifecycle execution.
            *  
            * @param {Function} callback - The callback function from the WrappedComponent that is to be executed
        */
        hasLoaded(callback) {
            if (performance.now() - this.state.constructedAt >= this.mandatoryLoadingTime) {
                callback();
                this.setState({ loading: false });
            } else {
                setTimeout(() => {
                    callback();
                    this.setState({ loading: false });
                }, this.mandatoryLoadingTime - (performance.now() - this.state.constructedAt));
            }
        }

        render() {
            const list = [];
            let index = 0;

            if(this.state.loading && !this.state.error) {
                list.push(<Loader key={`application_${this.state.uuid}_loader`} />);
            }

            if(this.state.error) {
                list.push(<div key={`application_${this.state.uuid}_${index++}`}>
                    {this.state.errorMessage != "" ? this.state.errorMessage : "An error occurred"}
                </div>);
            } else {
                list.push(<WrappedComponent
                    key={`application_${this.state.uuid}_${index++}`}
                    hasLoaded={this.hasLoaded.bind(this)}
                    isLoading={this.state.loading}
                    hasErrored={(errorMessage) => this.setState({ error: true, errorMessage })}
                    {...this.props}
                />);
            }

            return list;
        }
    }

    return BehaviourWrappedComponent;
}

ApplicationBehaviour.propsInformation = {
    hasLoaded: {
        type: "Function",
        description: "A function that must be called when the component has successfully loaded. It has a function parameter that will be executed after enough time has passed for the component to load. As of now, it is 2s"
    },
    isLoading: {
        type: "Boolean",
        description: "Indicates if the wrapped component has loaded successfully. This is here to prevent the component from needing to render itself twice needlessly."
    },
    hasErrored: {
        type: "Function",
        description: "A function with an optional error message that can be called when the component has errored"
    }
}

export default ApplicationBehaviour;