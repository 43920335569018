import React from "react";
import ReactDOM from 'react-dom/client';
import { Provider } from "react-redux";

import axiosBackend from "../core/api/backend";

import {render} from "ejs";

import ConfigureStore from "../reduxconfig/ConfigureStore";
let store = ConfigureStore({});

import * as DataDelegator from "../components/smart/delegator/DataDelegator"
import RenderComponents from "../components/smart/RenderComponents";
import ApplicationBehaviour from "./ApplicationBehaviour";
DataDelegator.setStore(store);

/**
    * Render components on a Page
    *  
    * @param {string} identifier - The identifier of the page whose configuration is to be fetched and rendered
    * @returns An array of rendered React.Component
 */
class SmartApp extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            fetchedConfiguration: [],
            configuration: [],
            loginRequired: false,
        }
    }

    componentDidMount() {
        axiosBackend.post(render("/service/pages/get/<%= identifier %>", this.props))
            .then(response => this.props.hasLoaded(() => {
                const formConfigurationResponse = response.data.results[0];

                this.setState({
                    fetchedConfiguration: formConfigurationResponse.frontendConfiguration,
                    configuration: formConfigurationResponse.frontendConfiguration
                });
            }))
            .catch(e => {
                console.error(e);
                this.props.hasLoaded(() => {
                    // this.setLoginForm();
                });
            })
    }

    render() {
        let propsToPass = { ...this.props };

        return (
            <Provider store={store}>
                <div>
                    {RenderComponents(this.state.configuration, propsToPass)}
                </div>
            </Provider>
        );
    }
}

const RobostackInjectableSmartApp = ApplicationBehaviour(SmartApp);

function loadRobostackApp() {
    // Render by class name
    document.querySelectorAll(".robostack-app").forEach(node => {
        // Generate the props for the Page by getting all the attributes from the DOM and passing it as props to the React.Component
        let arr = [];

        const atts = node.attributes;
        for (var i = 0; i < atts.length; i++) {
            arr.push(atts[i].nodeName);
        }

        let props = arr.reduce((acc, item) => {
            if(item == "props") {
                acc = {
                    ...acc,
                    ...JSON.parse(node.getAttribute(item))
                }
            } else {
                acc[item] = node.getAttribute(item);
            }

            return acc;
        }, {});

        const root = ReactDOM.createRoot(node);

        root.render(
            <RobostackInjectableSmartApp {...props} />
        );
    });
}

window.loadRobostackApp = loadRobostackApp;

loadRobostackApp();